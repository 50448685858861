import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className=" min-h-[3vh] z-20 p-2 text-sm bottom-0 fixed w-screen bg-[#161615] text-white flex justify-between items-center pl-12 pr-12">
      <a
        href="https://www.instagram.com/daidai_experience/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex hidden sm:flex"
      >
        <div className="text-2xl">
          <FaInstagram />
        </div>
        <div className="font-medium mt-1 ml-1">FOLLOW ME</div>
      </a>
      <a href="mailto:hello@daidaiexperience.com" className="flex hidden sm:flex">
        <div>
          <AiOutlineMail className="text-2xl" />
        </div>
        <div className="font-medium mt-1 ml-1"> hello@daidaiexperience.com</div>
      </a>
      <div className="flex hidden sm:flex">
        <div>
          <FaWhatsapp className="text-2xl" />
        </div>
        <a
          href="https://wa.me/33650970570?text=Hi!%20I%20am%20interested%20in%20finding%20out%20more%20about%20daidai%20experience!"
          className="font-medium mt-1 ml-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          +33 6 50 97 05 70
        </a>
      </div>
    </div>
  );
};

export default Footer;
