import React, { useState, useEffect, useRef } from 'react';
import backgroundImage from '../assets/landingpage.jpg';
import BookNow from './BookNow';
import useInView from '../hooks/useInView';

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const landingPageRef = useRef(null);
  const isInView = useInView(landingPageRef); // Use the hook

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 100); // Slight delay to trigger the animation
  }, []);

  return (
    <div className="h-screen w-screen relative" id="landing-page" ref={landingPageRef}>
      <img
        src={backgroundImage}
        className={`w-full h-full object-cover transition-opacity duration-[2000ms] ${
          isLoaded && isInView ? 'opacity-100' : 'opacity-0'
        }`}
        style={{ objectPosition: 'center 60%' }} // Adjust the position of the image
      />
      <div
        className={`absolute inset-0 flex flex-col md:flex-row md:items-center justify-center transition-opacity duration-[2000ms] delay-[500ms] ${
          isLoaded && isInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {/* Left Side: BOOKING CALENDAR */}
        <div
          className={`pt-20 md:pt-0 w-1/2 md:w-1/6 transform transition-transform duration-[2000ms] delay-[1500ms] ${
            isLoaded && isInView ? 'translate-x-0' : '-translate-x-full'
          } order-2 md:order-1`}
        >
          <BookNow />
        </div>

        {/* Spacer */}
        <div className="w-1/6 order-none md:order-2"></div>

        {/* Right Side: TITLE */}
        <div
          className={`mx-auto md:mx-0 w-4/6 text-left transform transition-transform duration-[2000ms] delay-[500ms] ${
            isLoaded && isInView ? 'translate-x-0' : 'translate-x-full'
          } order-1 md:order-3`}
        >
          <h1 className="text-white font-bold leading-loose">
            <span className="text-5xl font-extrabold md:text-7xl">ENJOY BURGUNDY</span>
            <br />
            <span className="text-3xl md:text-6xl">LIKE A NATIVE</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
