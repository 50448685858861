import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Logo } from '../assets/logohorizontal.svg';
import LogoMobile from '../assets/logoMobile.png';
import { FaInstagram, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { HiMenu, HiX } from 'react-icons/hi';

const NavBar = () => {
  const [scrolledPastSummary, setScrolledPastSummary] = useState(false);
  const [scrolledPastSummaryOneQuarter, setScrolledPastSummaryOneQuarter] = useState(false);
  const [scrolledPastSummaryThreeQuarters, setScrolledPastSummaryThreeQuarters] = useState(false);
  const [scrolledPastPrograms, setScrolledPastPrograms] = useState(false);
  const [scrolledPastProgramsThreeQuarters, setScrolledPastProgramsThreeQuarters] = useState(false);
  const navBarRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const summary = document.getElementById('summary');
      const programs = document.getElementById('programs');
      const bestSpots = document.getElementById('bestSpots');
      const navBar = navBarRef.current;
      if (summary && programs && navBar && bestSpots) {
        const summaryHeight = summary.offsetHeight;
        const summaryTop = summary.offsetTop;
        const summaryFirstTriggerPoint = summaryTop + summaryHeight * 0.18;
        const summaryTriggerPoint = summaryTop + summaryHeight * 0.95;
        const navBarBottom = navBar.getBoundingClientRect().bottom + window.scrollY;
        const programsHeight = programs.offsetHeight;
        const programsTop = programs.offsetTop;
        const programsThreeQuartersTriggerPoint = programsTop + programsHeight * 0.95;

        // Check if the bottom of the navbar has touched the top of the summary section
        setScrolledPastSummary(navBarBottom >= summaryTop);
        // Check if the bottom of the navbar has scrolled past 1/4 of the summary section
        setScrolledPastSummaryOneQuarter(navBarBottom >= summaryFirstTriggerPoint);
        // Check if the bottom of the navbar has scrolled past 3/4 of the summary section
        setScrolledPastSummaryThreeQuarters(navBarBottom >= summaryTriggerPoint);
        // Check if the bottom of the navbar has touched the top of the programs image
        setScrolledPastPrograms(navBarBottom >= programsTop);
        // Check if the bottom of the navbar has scrolled past 3/4 of the programs section
        setScrolledPastProgramsThreeQuarters(navBarBottom >= programsThreeQuartersTriggerPoint);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Determine if the navbar background should be white based on the scroll state
  const navBarBackgroundClass = scrolledPastPrograms
    ? 'bg-[#161615] md:bg-white'
    : 'bg-transparent';

  // Determine the logo color based on the scroll state
  const logoColor =
    scrolledPastProgramsThreeQuarters || scrolledPastPrograms || scrolledPastSummary
      ? 'text-[#1d1d1b]'
      : 'text-white';

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  return (
    <div
      ref={navBarRef}
      className={`fixed top-0 left-0 w-screen z-50 pt-1 md:pt-4 md:pt-10 pl-4 md:pl-10 md:pr-10 transition-colors duration-500 ${navBarBackgroundClass}`}
    >
      <div className="flex flex-row w-[99%] justify-between items-center md:items-end pb-1 md:pb-2 pr-4 border-b-[0.1rem] border-b-transparent md:border-b-white">
        {/* Logo */}
        <div className="cursor-pointer" onClick={() => scrollToSection('landing-page')}>
          <Logo
            className={`hidden md:block w-[25vw] h-auto transition-all duration-500 ${logoColor}`}
          />
          <img src={LogoMobile} className="block md:hidden w-[12vw] h-auto" />
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex flex-row space-x-20 text-base font-bold">
          <a
            href="#tours"
            className={`hover:underline transition-colors duration-500 ${
              scrolledPastProgramsThreeQuarters
                ? 'text-black'
                : scrolledPastPrograms
                  ? 'text-black'
                  : scrolledPastSummaryThreeQuarters
                    ? 'text-black'
                    : 'text-white'
            }`}
            onClick={() => {
              const element = document.getElementById('bestSpots');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            TOURS
          </a>
          <a
            href="#events"
            className={`hover:underline transition-colors duration-500 ${
              scrolledPastProgramsThreeQuarters
                ? 'text-black'
                : scrolledPastPrograms
                  ? 'text-black'
                  : scrolledPastSummaryThreeQuarters
                    ? 'text-black'
                    : 'text-white'
            }`}
            onClick={() => {
              const element = document.getElementById('events');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            EVENTS
          </a>
          <a
            href="#me"
            className={`hover:underline transition-colors duration-500 ${
              scrolledPastProgramsThreeQuarters
                ? 'text-black'
                : scrolledPastPrograms
                  ? 'text-black'
                  : scrolledPastSummaryThreeQuarters
                    ? 'text-black'
                    : 'text-white'
            }`}
            onClick={() => {
              const element = document.getElementById('aboutme');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            ME
          </a>
        </div>

        {/* Mobile Icons */}
        <div className="md:hidden flex space-x-6 text-2xl">
          <a
            href="https://www.instagram.com/daidai_experience/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram
              className={`hover:underline transition-colors duration-500 ${
                scrolledPastSummaryThreeQuarters ? 'text-white' : 'text-black'
              }`}
            />
          </a>
          <a href="mailto:hello@daidaiexperience.com">
            <FaEnvelope
              className={`hover:underline transition-colors duration-500 ${
                scrolledPastSummaryThreeQuarters ? 'text-white' : 'text-black'
              }`}
            />
          </a>
          <a
            href="https://wa.me/33650970570?text=Hi!%20I%20am%20interested%20in%20finding%20out%20more%20about%20daidai%20experience!"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp
              className={`hover:underline transition-colors duration-500 ${
                scrolledPastSummaryThreeQuarters ? 'text-white' : 'text-black'
              }`}
            />
          </a>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`hover:underline transition-colors duration-500 ${
              scrolledPastSummaryThreeQuarters ? 'text-white' : 'text-black'
            }`}
          >
            {isMenuOpen ? <HiX /> : <HiMenu />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden fixed top-0 left-0 w-full h-screen bg-gray-200 pt-10 px-8 overflow-y-auto overflow-x-hidden z-50">
            <div className="flex justify-end mb-4">
              <button onClick={() => setIsMenuOpen(false)} className="text-2xl text-gray-800">
                <HiX />
              </button>
            </div>
            <div className="flex flex-col items-center space-y-12">
              <img
                src={LogoMobile}
                alt="Mobile Logo"
                className="w-[30vw] h-auto mb-6 max-w-full"
                onClick={() => scrollToSection('landing-page')}
              />
              <a
                href="#tours"
                className="text-lg  text-gray-800"
                onClick={() => scrollToSection('bestSpots')}
              >
                TOURS
              </a>
              <a
                href="#events"
                className="text-lg  text-gray-800"
                onClick={() => scrollToSection('events')}
              >
                EVENTS
              </a>
              <a
                href="#me"
                className="text-lg text-gray-800"
                onClick={() => scrollToSection('aboutme')}
              >
                ABOUT ME
              </a>
              <a
                href="#me"
                className="text-lg text-gray-800"
                onClick={() => {
                  if (window.innerWidth <= 768) {
                    // Adjust the width to your mobile screen breakpoint
                    scrollToSection('contactmeMobile');
                  } else {
                    scrollToSection('contactme');
                  }
                }}
              >
                CONTACT ME
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
